import React, {Component} from "react";
import * as firebase from "firebase/app";
import "firebase/database";
import Gedicht from "./Scenes/Gedicht";
import Welcome from "./Scenes/Welcome";
import {slidesEnum} from "./slidesEnum";
import DogTranslate from "./Scenes/DogTranslate";

const image = require("./Images/background.jpg")

interface IProps {
    
}

interface IState {
    slide:number
}

class User extends Component<IProps,IState>{
    private sounds:HTMLAudioElement[]


    constructor(props:IProps){
        super(props)
        this.state={
            slide:slidesEnum.welkom
        }
        this.sounds=[new Audio(require("./music/sinterklaasGedicht.mp3" )),new Audio(require("./music/sinterklaasMuziek.mp3"))]
        this.sounds[0].load()
        this.sounds[1].load()
        this.sounds[1].loop=true
        this.sounds[1].volume=0.3
    }

    componentDidMount(): void {
        var database= firebase.database()
        var gedicht=database.ref("sintGedicht")
        this.sounds[0].onended=ev => {
            gedicht.set(false)
        }
        gedicht.on("value", a => {
            if(a.val()){
                this.sounds[0].play()
            }else{
                this.sounds[0].pause()
                this.sounds[0].currentTime=0
            }
        })
        var muziek = database.ref("sintMuziek")
        muziek.on("value", a => {
        if(a.val()){
            this.sounds[1].play()

        }else{
            this.sounds[1].pause()
        }})
        var slide=database.ref("slide")
        slide.on("value", a => {
            this.setState({slide: a.val()})
        })
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (<div >
            <div style={{position:"relative"}}>
                <div className="centerMiddle">
                    {
                        this.state.slide===slidesEnum.welkom &&
                        <Welcome/>

                    }
                    {
                        this.state.slide===slidesEnum.gedicht &&
                        <Gedicht/>
                    }
                    {
                        this.state.slide===slidesEnum.doglate &&
                        <DogTranslate/>
                    }
                </div>
            </div>
            <img src={image}  className="moreDark" style={{overflow:"hidden",minHeight:"99vh",maxHeight:"100vh",minWidth:"100%",maxWidth:"100%",objectFit:"cover",objectPosition:"100% 100%",margin:0}}/>
        </div>)
    }


}export default User