import React from "react";
const sintPad= require("../Images/Sintpad.png")

function Welcome() {
    return(
        <div>
            <img style={{width:"75vw"}} src={sintPad}/>
            <p className="welkom">Verbinding maken met de stoomboot...</p>
        </div>
    )
}
export default Welcome