import React from "react";
import * as firebase from "firebase/app";
import "firebase/database";

interface IProps {

}

interface IState {
    text:string
    firstTime:boolean
    timeout:number
}

class DogTranslate extends React.Component<IProps,IState>{

    constructor(props:IProps){
        super(props)
        this.state={
            text:" ",
            firstTime:true,
            timeout: 0
        }
    }

    componentDidMount(): void {
        var database=firebase.database()
        var ref=database.ref("dogSounds")
        var ja=ref.child("bark1")
        var nee=ref.child("bark2")

        ja.on("value", a=> {
            this.setState({text:"translating..."})
            setTimeout(()=>{
                this.setState({text:"ja"})
                clearTimeout()
                var timeout=setTimeout(()=>{
                    this.setState({text:" "})
                },2000)
            },1500)

        })
        nee.on("value", a=> {
            this.setState({text:"translating..."})
            setTimeout(()=> {
                this.setState({text:"nee"})
                clearTimeout()
                var timeout=setTimeout(()=>{
                    this.setState({text:" "})
                },2000)
                },1500)
        })
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <div>
                <p className="noVertMargin center" >Doglate</p>
                <p className="noVertMargin center" style={{fontSize:20}}>De honden Vertaler!</p>
                <p className="center dogText ">{this.state.text}</p>
            </div>

        );
    }

}
export default DogTranslate