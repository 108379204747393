import React from "react";
import {Link} from "react-router-dom";

interface IProps {

}

class Navigator extends React.Component{


    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <div>
                <Link to={"/user"}>User</Link><br/>
                <Link to={"/dog"}>Dog</Link><br/>
                <Link to={"/admin"}>Admin</Link><br/>
            </div>
        )

    }
}
export default Navigator