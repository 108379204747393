import React from "react";

function Gedicht() {
    return(
        <p className="thinScrollBar gedicht">Beste Benthe,<br/><br/>
            Sint hoefde dit jaar niet heel erg lang na te denken over wat hij Benthe moest schenken.<br/>
            Hij kreeg van jou een hele lijst en is daarvoor het hele land doorgereisd.<br/>
            Alleen miste er iets belangrijks op deze lijst, iets wat veel inspanning vereist.<br/>
            Benthe wilde namelijk graag een hond! Iets wat sinterklaas ook fantastische vond!<br/>
            Daarom mag je even oppassen op de hond van sinterklaas. Maar let op hij is wel de baas.<br/>
            Maak de doos niet open want dan verlies je misschien een vinger. Want de hond ziet je dan als een indringen.<br/>
            Dus maak de hond niet boos. En gebruik hem alleen voor het zoeken naar cadeaus.<br/>
            1 keer blaffen is ja 2 keer is nee. En eh hij hoeft niet naar de wc.<br/>
            De hond is alleen nog wel verstop. Dus veel plezier met zoeken!<br/><br/>
            Groeten,<br/>
            Piet en Sint<br/>
        </p>
    )
}
export default Gedicht