import React, {Component} from "react";
import * as firebase from "firebase/app";
import "firebase/database"
import {slidesEnum} from "./slidesEnum";

interface IProps {

}
interface IState {
    crying:boolean
    gedicht:boolean
    slideNumber:number
    maxSlide:number
    muziek: boolean
}

class AdminPanel extends Component<IProps,IState> {
    constructor(props:IProps){
        super(props)
        this.state={
            crying:false,
            gedicht:false,
            slideNumber:0,
            maxSlide:0,
            muziek:false
        }
    }

    componentDidMount(): void {
        var database=firebase.database()
        var dogSounds=database.ref("dogSounds")
        dogSounds.child("cry").on("value", a => {
            this.setState({crying:a.val()})
        })
        var gedicht=database.ref("sintGedicht")
        gedicht.on("value", a =>{
            this.setState({gedicht:a.val()})
        })
        var muziek=database.ref("sintMuziek")
        muziek.on("value", a => {
            this.setState({muziek:a.val()})
        })
        var slides= database.ref("slide")
        slides.on("value", a => {
            this.setState({slideNumber:a.val()})
        })
        var numbersString = Object.keys(slidesEnum).filter((item) => {
            return !isNaN(Number(item));
        });
        console.log(numbersString)
        var numbers = numbersString.map(value => parseInt(value))
        var maxNumber=Math.max(...numbers)
        this.setState({maxSlide:maxNumber})
    }

    changeCrying=()=>{
        var database=firebase.database()
        var dogSounds=database.ref("dogSounds")
        dogSounds.child("cry").set(!this.state.crying)
    }
    changeGedicht=()=>{
        var database=firebase.database()
        var gedicht=database.ref("sintGedicht")
        gedicht.set(!this.state.gedicht)
    }

    changeMuziek=()=>{
        var database=firebase.database()
        var gedicht=database.ref("sintMuziek")
        gedicht.set(!this.state.muziek)
    }

    Bark1=()=>{
        var database=firebase.database()
        var dogSounds=database.ref("dogSounds")
            dogSounds.child("bark1").set(new Date().getTime())
    }

    Bark2=()=>{
        var database=firebase.database()
        var dogSounds=database.ref("dogSounds")
        dogSounds.child("bark2").set(new Date().getTime())
    }

    changeSlide=(amount:number)=>{
        var database=firebase.database()
        var slides= database.ref("slide")
        slides.set(this.state.slideNumber+amount)

    }

    render(){
        return (
            <div className={"AdminPanel"}>
                <p>Adminpanel</p>
                <div className="buttonList">
                    <button style={{backgroundColor:this.state.muziek? "red":"green"}} onClick={this.changeMuziek}>Muziek {this.state.muziek? "Uitzetten":"Aanzetten"}</button>
                    <button style={{backgroundColor:this.state.gedicht? "red":"green"}} onClick={this.changeGedicht}>Gedicht {this.state.gedicht? "Uitzetten":"Aanzetten"}</button>
                    <button style={{backgroundColor:this.state.crying? "red":"green"}} onClick={this.changeCrying}>Crying {this.state.crying? "Uitzetten":"Aanzetten"}</button>
                    <button style={{backgroundColor:"purple"}} onClick={this.Bark1}>Bark (Ja)</button>
                    <button style={{backgroundColor:"purple"}} onClick={this.Bark2}>Bark 2 keer (Nee)</button>
                </div>
                <div className="buttonlist">
                    <p>{this.state.slideNumber}</p>
                    {this.state.slideNumber!==0 &&
                        <button onClick={event => this.changeSlide(-1)}>Vorige</button>
                    }
                    {
                        this.state.slideNumber<this.state.maxSlide &&
                        <button onClick={event => this.changeSlide(1)}>Volgende</button>
                    }
                </div>
            </div>
        )
    }

}
export default AdminPanel