import React from "react";
import Dog from "./Dog";

interface IProps {

}

interface IState {
    click:boolean
}

class Dog1 extends React.Component<IProps,IState>{

    constructor(props:IProps){
        super(props)
        this.state={
            click:false
        }
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <div>
                {
                    this.state.click?
                        <Dog/>:
                        <button onClick={()=>this.setState({click:true})}>Activeer!</button>
                }
            </div>
        )
    }

}
export default Dog1