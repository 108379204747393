import React, {Component} from "react";
import * as firebase from "firebase/app";
import "firebase/database";



interface IProps {

}

interface IState {
    crying:boolean
}

class Dog extends Component<IProps,IState>{
    private sounds:HTMLAudioElement[];
    private audioContext:any
    private bark=require("./music/dogBark1.mp3")


    addToContext=(audioElement:HTMLAudioElement)=>{
            var source = this.audioContext.createMediaElementSource(audioElement);
            var analyser = this.audioContext.createAnalyser();
            source.connect(analyser);
            analyser.connect(this.audioContext.destination);
    }

    constructor(props:IProps){
        super(props)
        this.state={
            crying:false
        }
        this.sounds=[new Audio(require("./music/dogCry.mp3")),new Audio(this.bark)]
        this.sounds.forEach(value => value.load())
        this.sounds[0].loop=true
    }


    componentDidMount(): void {
        var database=firebase.database()
        var dogSounds=database.ref('dogSounds')
        dogSounds.child("cry").on("value", a => {
            var value =a.val()
            this.setState({crying:value})
        })
        dogSounds.child("bark1").on("value", a => {

            this.sounds[1].play()
        })
        dogSounds.child("bark2").on("value", a => {
            this.sounds[1].onended=ev => {
                this.sounds[1].play()
                this.sounds[1].onended=()=>{}
            }
            this.sounds[1].play()
        })
    }



    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if(this.state!==prevState){
            if(this.state.crying){
                this.sounds[0].play()
            }else{
                this.sounds[0].pause()
            }
        }
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <div>
                <p>Dogpage 1.9</p>
                <button onClick={event => new Audio().play()}>Klik hier</button>
            </div>
        )
    }
}
export default Dog