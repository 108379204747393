import React from 'react';
import './App.css';
import firebase from "firebase";
import {HashRouter as Router, Route, Switch} from 'react-router-dom'
import AdminPanel from "./AdminPanel";
import User from "./User";
import Dog1 from "./Dog1";
import Navigator from "./Navigator";

const App: React.FC = () => {
  var firebaseConfig = {
    apiKey: "AIzaSyCep43__yXQKaYc6D4c88Y0xckT_X_984I",
    authDomain: "sinterklaas-surprise.firebaseapp.com",
    databaseURL: "https://sinterklaas-surprise.firebaseio.com",
    projectId: "sinterklaas-surprise",
    storageBucket: "sinterklaas-surprise.appspot.com",
    messagingSenderId: "42703197133",
    appId: "1:42703197133:web:3fafc71c16ae1264912a84"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  return (
      <div>
        <Router>
          <Switch>
            <Route path={"/admin"} render={props => <AdminPanel/>} />
            <Route path={"/dog"} render={props => <Dog1 />}/>
            <Route path={"/user"} render={props => <User/>}/>
            <Route path={"/"} render={props => <Navigator/>}/>
          </Switch>
        </Router>
      </div>
  );
}

export default App;
